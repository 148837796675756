@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,800;0,900;1,800;1,900&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

@layer base {
  :root {
    --scrollbarBG: white;
    --thumbBG: #121212;
    --dark: #121212;
    --gray: #808080;

    /* Full grid area variable */
    --fullGrid: 1 / 1 / -1 / -1;
  }
  html {
    scroll-behavior: smooth;
  }
  html,
  body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
      Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  }
  body {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) #121212 !important;
    @apply min-h-screen font-body;
  }
  h1,
  h2,
  h3,
  h4 {
    @apply font-heading;
  }
  a {
    color: inherit;
    @apply underline-offset-4 decoration-1 decoration-wavy hover:decoration-wavy border-grey hover:opacity-80;
  }
  p {
    @apply text-grey leading-relaxed mb-4 max-w-3xl;
  }
  li {
    @apply text-grey leading-relaxed max-w-3xl;
  }
  * {
    box-sizing: border-box;
  }
  label.radio-button {
    @apply block relative border-black border  rounded-sm font-medium py-2   text-lg text-black cursor-pointer focus:ring-1 focus:border-black  focus:ring-black peer-checked:bg-black peer-checked:text-white hover:scale-98 duration-300 mt-2 w-32 text-center;
  }
  input.form-input,
  textarea.form-input {
    @apply border border-black  rounded-sm w-full font-medium py-4  px-8 text-lg text-black cursor-text focus:ring-1 focus:border-black  focus:ring-black;
  }
  input.form-input.error,
  textarea.form-input.error {
    @apply border-error focus:border-error focus:ring-error;
  }
  input.form-submit {
    @apply bg-black text-lg font-bold text-white rounded-sm px-8 py-3  cursor-pointer hover:scale-98 duration-300 disabled:hover:scale-100 disabled:bg-opacity-30;
  }
}
