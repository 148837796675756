/* Hamburger Menu  */
/* This is forked from Mikael Ainalem's source code: https://codepen.io/ainalem/pen/LJYRxz All credit goes to him <3 */
.hamWrapper {
  @apply block group-hover:scale-150;
}
.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @apply pointer-events-auto object-contain w-full h-full;
}
.hamRotate.active {
  transform: rotate(45deg);
}
.hamRotate180.active {
  transform: rotate(180deg);
}
.line {
  transition: stroke-dasharray 500ms, stroke-dashoffset 500ms;
  stroke-width: 5.5;
  stroke-linecap: round;
  @apply fill-none stroke-white;
}
.ham .top {
  stroke-dasharray: 40 160;
}
.ham .middle {
  stroke-dasharray: 40 142;
  transform-origin: 50%;
  transform: rotate(0deg);

  transition: transform 500ms;
}
.ham .bottom {
  stroke-dasharray: 40 85;
  transform-origin: 50%;
  transition: transform 500ms, stroke-dashoffset 500ms;
}

.ham.active .top {
  stroke-dashoffset: -64px;
}
.ham.active .middle {
  transform: rotate(90deg);
}
.ham.active .bottom {
  stroke-dashoffset: -64px;
}
/* END OF HAM STYLING */
